export class simulator {
    data:any;
    constructor(){
        this.data = {
            VekaletListesi: [{
                noter: {
                    il: 'İzmir',
                    noterAd: 'İzmir 8.Noter',
                    yevmiyeNo: '53242',
                    vekaletnameTarih: '2019-02-03'
                },
                muvekkiller: [{
                    genelBilgi: {
                        id: 1,
                        tc: '50662455016',
                        adSoyad: 'Potansiyel Müvekkil',
                        tarafTuru: 1,
                        cinsiyet: 1,
                        durum: 1,
                        harcMuaf: true,
                        muvTuru: 1,
                        vergiNo: '',
                    },
                    adresler: [{
                        id: 1,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Kalabak',
                        il: 'İzmir',
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İstanbul Beşiktaş',
                            il: 'İstanbul',
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                    iletisimler: [{
                        id: 1,
                        tur: 'E-posta',
                        deger: 'BesimBesim@gmail.com',
                    },
                        {
                            id: 2,
                            tur: 'Telefon No',
                            deger: '05057816432',
                        },
                        {
                            id: 3,
                            tur: 'Web Sitesi',
                            deger: 'ldp.com',
                        },
                    ],
                    hesaplar: [{
                        id: 1,
                        ad: 'Besim TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 2,
                            ad: 'Besim Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: {
                        tur: 'Nüfus Cüzdanı',
                        seri: 'A',
                        seriNo: '23',
                        babaAdi: 'ahmet',
                        anneAdi: 'ayse',
                        dogumYeri: 'New York',
                        dogumTarihi: '1964-11-25',
                        il: 'İzmir',
                        ilce: 'Merkez',
                        mahalle: 'Erzene',
                        ciltNo: '0123',
                        aileSiraNo: '457',
                        aciklama: 'Keman Virtiözü'
                    }
                },],
                notlar: [{
                    tur: 'Görüşme',
                    icerik: 'notun içeriği bu şekilde',
                    tarih: '2020-01-02'
                },
                    {
                        tur: 'Diğer',
                        icerik: 'notun içeriği şu şekilde',
                        tarih: '2020-03-02'
                    },
                ]
            },],
            editingTakipObject: Object,
            TakipListesi: [{
                takipGenel: {
                    kartNo: 1,
                    ikiliMi: false,
                    takipTipiId: 1, // eğer takip tiplerinin keyi auto-increment olacaksa 1, takip kodunu alacaksa 49, Not: takip kodu "4-5" olan takip tipi var.
                    takipYolu: 1,
                    takipAltTipi: 1,
                    statu: 1,
                    il: 34,
                    icraDairesi: 8,
                    dosyaNo: '2020/0001',
                    takipTarihi: '2020-01-16',
                    alacakKalemleriniTopla: true,
                },
                Taraflar: [{
                    genelBilgi: {
                        id: 1,
                        tc: '50662455016',
                        adSoyad: 'Besim Tibuk',
                        tarafTuru: '1', // Alacaklı
                        cinsiyet: '1',
                        durum: '4',
                        harcMuaf: false,
                        muvTuru: '1', //şahıs
                        vergiNo: '',
                    },
                    adresler: [{
                        id: 1,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Kalabak',
                        il: 'İzmir',
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İstanbul Beşiktaş',
                            il: 'İstanbul',
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                    iletisimler: [{
                        id: 1,
                        tur: 'E-posta',
                        deger: 'kardelen_yilmaz_cellist@gmail.com',
                    },
                        {
                            id: 2,
                            tur: 'Telefon No',
                            deger: '05057816432',
                        },
                        {
                            id: 3,
                            tur: 'Web Sitesi',
                            deger: 'kardelen1project.com',
                        },
                    ],
                    hesaplar: [{
                        id: 1,
                        ad: 'Kardelen TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 2,
                            ad: 'Kardelen Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: {
                        nufus: {
                            tur: 'Nüfus Cüzdanı',
                            seri: 'A',
                            seriNo: '23',
                            babaAdi: 'Kaan',
                            anneAdi: 'Bilge',
                            dogumYeri: 'New York',
                            dogumTarihi: '2024-11-25',
                            il: 'İzmir',
                            ilce: 'Merkez',
                            mahalle: 'Erzene',
                            ciltNo: '0123',
                            aileSiraNo: '457',
                            siraNo: '457',
                            aciklama: 'Keman Virtiözü'
                        },
                        ehliyet: {
                            tur: 'Ehliyet',
                            ehliyetNo: '20215126',
                            dogumYeri: 'İzmir',
                            dogumTarihi: '2001-10-02',
                            aciklama: 'bu bir ehliyetttir',
                        },
                        pasaport: {
                            tur: 'Pasaport',
                            pasTur: 'yeşil',
                            ulkeKodu: 5,
                            no: '2424',
                            dogumYeri: 'Minnesota',
                            dogumTarihi: '2001-12-14',
                            uyrugu: 'Amerikan',
                            aciklama: 'La Pasaporte , Aymeric Laporte',
                        },
                        avukatKimlik: {
                            tur: 'Avukat Kimlik',
                            dogumYeri: 'Rome',
                            dogumTarihi: '1993-11-01',
                            aciklama: 'Deus Voult',
                        },
                    },
                },
                    {
                        genelBilgi: {
                            id: 2,
                            tc: '50662455016',
                            adSoyad: 'Doğu Perinçek',
                            tarafTuru: '2', // Borçlu
                            cinsiyet: '1',
                            durum: '4',
                            harcMuaf: false,
                            muvTuru: '1',
                            vergiNo: '',
                        },
                        adresler: [{
                            id: 1,
                            adresTuru: 'Yurt İçi İşyeri Adresi',
                            postaKodu: '35014',
                            adres: 'İzmir Kalabak',
                            il: 'İzmir',
                            ilce: 'Merkez',
                            mersisAdresi: true,
                        },
                            {
                                id: 2,
                                adresTuru: 'Yurt Dışı İşyeri Adresi',
                                postaKodu: '35430',
                                adres: 'İstanbul Beşiktaş',
                                il: 'İstanbul',
                                ilce: 'Merkez',
                                mersisAdresi: false,
                            },
                        ],
                        iletisimler: [{
                            id: 1,
                            tur: 'E-posta',
                            deger: 'kardelen_yilmaz_cellist@gmail.com',
                        },
                            {
                                id: 2,
                                tur: 'Telefon No',
                                deger: '05057816432',
                            },
                            {
                                id: 3,
                                tur: 'Web Sitesi',
                                deger: 'kardelen1project.com',
                            },
                        ],
                        hesaplar: [{
                            id: 1,
                            ad: 'Kardelen TL',
                            tur: 'Vadesiz TL Hesabı',
                            bankaMi: '2',
                            iban: 'TR-131353464567',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                            {
                                id: 2,
                                ad: 'Kardelen Dolar',
                                tur: 'Vadesiz Dolar Hesabı',
                                bankaMi: '2',
                                iban: 'USD-12325342131256',
                                bankaAdi: '',
                                subeAdi: '',
                                hesapNo: '',
                            },
                        ],
                        kimlikBilgileri: {
                            nufus: {
                                tur: 'Nüfus Cüzdanı',
                                seri: 'A',
                                seriNo: '23',
                                babaAdi: 'Kaan',
                                anneAdi: 'Bilge',
                                dogumYeri: 'New York',
                                dogumTarihi: '2024-11-25',
                                il: 'İzmir',
                                ilce: 'Merkez',
                                mahalle: 'Erzene',
                                ciltNo: '0123',
                                aileSiraNo: '457',
                                siraNo: '457',
                                aciklama: 'Keman Virtiözü'
                            },
    
                            ehliyet: {
                                tur: 'Ehliyet',
                                ehliyetNo: '20215126',
                                dogumYeri: 'İzmir',
                                dogumTarihi: '2001-10-02',
                                aciklama: 'bu bir ehliyetttir',
                            },
                            pasaport: {
                                tur: 'Pasaport',
                                pasTur: 'yeşil',
                                ulkeKodu: 5,
                                no: '2424',
                                dogumYeri: 'Minnesota',
                                dogumTarihi: '2001-12-14',
                                uyrugu: 'Amerikan',
                                aciklama: 'La Pasaporte , Manchester City Laporte',
                            },
                            avukatKimlik: {
                                tur: 'Avukat Kimlik',
                                dogumYeri: 'Rome',
                                dogumTarihi: '1993-11-01',
                                aciklama: 'Deus Voult',
                            },
    
                        },
                        avukatlar: [{
                            id: 1,
                            adSoyad: 'Kemal Unakıtan',
                            baroNo: '252215136',
                            adres: '123/32 sokak no 168 ergene mah konak manisa',
                            il: 1,
                            ilce: 1,
                            telefon: '05057885312',
                            tebligTarihi: '2020-05-12'
                        }]
                    },
                ],
                Avukatlar: [{
                    personelBilgileri: {
                        id: 1,
                        personelTuru: 'Avukat',
                        adSoyad: 'Av. Deniz Erdinç',
                        tcNo: '123412341234123',
                        cinsiyet: '2',
                        iseBaslamaTarihi: '2020-01-02',
                        istenAyrilmaTarihi: '',
                        maas: '8810',
                        sgkNo: '2235498761',
                        secilenAv: true,
                    },
                    iletisimler: [{
                        id: 1,
                        tur: 'E-posta',
                        deger: 'deniz.yzlm@gmail.com',
                    },
                        {
                            id: 2,
                            tur: 'Telefon No',
                            deger: '05057816431',
                        },
                        {
                            id: 3,
                            tur: 'Web Sitesi',
                            deger: 'den-iz.com',
                        },
                    ],
                    hesaplar: [{
                        id: 1,
                        ad: 'deniz TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 2,
                            ad: 'deniz Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: [{
                        id: 1,
                        tur: 'Nüfus Cüzdanı',
                        seri: '123456',
                        serino: '1235467',
                        babaadi: 'Ahmet',
                        anneadi: 'Ayşe',
                        dogumyeri: 'Konak',
                        il: 35,
                        ilce: 1,
                        mahallekoy: 'İçmeler',
                        dogumtarihi: '2020-10-10',
                        ciltno: '21323',
                        ailesirano: '132546',
                        sirano: '111111',
                        aciklama: 'Bu bir açıklama metnidir',
                    },],
                    adresler: [{
                        id: 1,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Urla Gülbahçe 9111 sokak No 25',
                        il: 35,
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İzmir Urla İçmeler 1088 sokak No 5',
                            il: 34,
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                },
    
                ],
                Ibanlar: [{
                    id: 5,
                    avukatId: 1,
                    ad: 'Kaan TL',
                    tur: 'Vadesiz TL Hesabı',
                    bankaMi: '2',
                    iban: 'TR-131353464567',
                    bankaAdi: '',
                    subeAdi: '',
                    hesapNo: '',
                },],
    
                AlacakKalemleri: [{
    
                    kiraKontrati: {
                        sozlesmeTuru: 2,
                        sozlesmeBaslangicTarihi: "2020-01-01",
                        sozlesmeBitisTarihi: "2020-01-01",
                        adresTuru: 1,
                        il: 1,
                        ilce: 1,
                        noter: {
                            il: 'İzmir',
                            noterAd: 'İzmir 8.Noter',
                            yevmiyeNo: '53242',
                            vekaletnameTarih: '2019-02-03'
                        },
                        kiralar: [{
                            id: 1,
                            kiraBaslangicTarihi: "2010-01-02",
                            kiraBitisTarihi: "2012-03-04",
                            tutar: 2400,
                            paraBirimi: 1,
                        }],
                        protesto: {
                            turId: 1,
                            noterIl: 34,
                            noter: 3,
                            yevmiyeNo: '12321541',
                            tarih: '2015-01-23',
                            tutar: 1800
                        },
                        ihtar: {
                            turId: 2,
                            noterIl: 1,
                            noter: 1,
                            yevmiyeNo: '3232424',
                            tarih: '2017-01-23',
                            tutar: 4100
                        },
                    },
                    sozlesme: {
                        sozlesmeTarihi: '2020-01-01',
                        vadeTarihi: '2020-02-02',
                        faizBaslangicTarihi: '2020-03-03',
                        sozlesmeIsmi: 'Sözleşme 1',
                        adresTuru: 1,
                        il: 1,
                        ilce: 1,
                        pulOlan: '25000',
                        pulGercek: '2650',
                        ihtar: {
                            turId: 2,
                            noterIl: 1,
                            noter: 1,
                            yevmiyeNo: '3232424',
                            tarih: '2017-01-23',
                            tutar: 4100
                        }
                    }, // sebo
                    tuketiciHakemHeyetiKarari: {
                        belgeAciklamasi: 'Tuketici Hakem Heyeti',
                        sözlesmeTarihi: '2016-01-02',
                        vadeTarihi: '2019-01-02',
                        faizBaslangicTarihi: '2016-09-01',
                        paraBirimi: 'TL',
                        kararNo: '231546',
                    }, // sebo
                    kiraFarkiAlacagi: {
                        sozlesmeTuru: 1,
                        sozlesmeBaslangicTarihi: "2020-01-01",
                        sozlesmeBitisTarihi: "2020-01-01",
                        adresTuru: 1,
                        il: 1,
                        ilce: 1,
                        noter: {
                            il: 'İzmir',
                            noterAd: 'İzmir 8.Noter',
                            yevmiyeNo: '53242',
                            vekaletnameTarih: '2019-02-03'
                        },
                        kiralar: [{
                            id: 1,
                            kiraBaslangicTarihi: "2010-01-02",
                            kiraBitisTarihi: "2012-03-04",
                            tutar: 2400,
                            paraBirimi: 1,
                        }],
                        protesto: {
                            turId: 1,
                            noterIl: 34,
                            noter: 3,
                            yevmiyeNo: '12321541',
                            tarih: '2015-01-23',
                            tutar: 1800
                        },
                        ihtar: {
                            turId: 2,
                            noterIl: 1,
                            noter: 1,
                            yevmiyeNo: '3232424',
                            tarih: '2017-01-23',
                            tutar: 4100
                        },
                    },
    
                }],
                TakipOncesiTahsilatlar: [{
                    id: 1,
                    kimden: 2, //tarafId
                    kime: 1,    //tarafId
                    tahsilatTarihi: '2020-03-01',
                    tahsilatTutari: 500,
                    paraBirimi: 1,
                }]
            }],
            // ↓↓ TAKİBİN DIŞI ↓↓
            Taraflar: [
                {
                    genelBilgi: {
                        id: 53,
                        tc: '50662455016',
                        adSoyad: 'Potansiyel Borçlu',
                        tarafTuru: 2,
                        cinsiyet: 'Erkek',
                        durum: 'Yetişkin',
                        harcMuaf: true,
                        muvTuru: 1,
                        vergiNo: '',
                    },
                    adresler: [{
                        id: 1,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Kalabak',
                        il: 'İzmir',
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İstanbul Beşiktaş',
                            il: 'İstanbul',
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                    iletisimler: [{
                        id: 1,
                        tur: 'E-posta',
                        deger: 'BesimBesim@gmail.com',
                    },
                        {
                            id: 2,
                            tur: 'Telefon No',
                            deger: '05057816432',
                        },
                        {
                            id: 3,
                            tur: 'Web Sitesi',
                            deger: 'ldp.com',
                        },
                    ],
                    hesaplar: [{
                        id: 1,
                        ad: 'Besim TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 2,
                            ad: 'Besim Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: [{
                        tur: 'Nüfus Cüzdanı',
                        seri: 'A',
                        seriNo: '23',
                        babaAdi: 'ahmet',
                        anneAdi: 'ayse',
                        dogumYeri: 'New York',
                        dogumTarihi: '1964-11-25',
                        il: 'İzmir',
                        ilce: 'Merkez',
                        mahalle: 'Erzene',
                        ciltNo: '0123',
                        aileSiraNo: '457',
                        aciklama: 'Keman Virtiözü'
                    },]
                },
                {
                    genelBilgi: {
                        id: 54,
                        tc: '50662455016',
                        adSoyad: 'Potansiyel Alacaklı',
                        tarafTuru: 1,
                        cinsiyet: 'Erkek',
                        durum: 'Yetişkin',
                        harcMuaf: true,
                        muvTuru: 1,
                        vergiNo: '',
                    },
                    adresler: [{
                        id: 1,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Kalabak',
                        il: 'İzmir',
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İstanbul Beşiktaş',
                            il: 'İstanbul',
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                    iletisimler: [{
                        id: 1,
                        tur: 'E-posta',
                        deger: 'BesimBesim@gmail.com',
                    },
                        {
                            id: 2,
                            tur: 'Telefon No',
                            deger: '05057816432',
                        },
                        {
                            id: 3,
                            tur: 'Web Sitesi',
                            deger: 'ldp.com',
                        },
                    ],
                    hesaplar: [{
                        id: 1,
                        ad: 'Besim TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 2,
                            ad: 'Besim Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: {
                        tur: 'Nüfus Cüzdanı',
                        seri: 'A',
                        seriNo: '23',
                        babaAdi: 'ahmet',
                        anneAdi: 'ayse',
                        dogumYeri: 'New York',
                        dogumTarihi: '1964-11-25',
                        il: 'İzmir',
                        ilce: 'Merkez',
                        mahalle: 'Erzene',
                        ciltNo: '0123',
                        aileSiraNo: '457',
                        aciklama: 'Keman Virtiözü'
                    }
                },
            ],
    
            Avukatlar: [
                {
                    personelBilgileri: {
                        id: 1,
                        personelTuru: 'Avukat',
                        adSoyad: 'Av. Deniz Erdinç',
                        tcNo: '123412341234123',
                        cinsiyet: '2',
                        iseBaslamaTarihi: '2020-01-02',
                        istenAyrilmaTarihi: '',
                        maas: '8810',
                        sgkNo: '2235498761',
                        secilenAv: true,
                    },
                    iletisimler: [{
                        id: 1,
                        tur: 'E-posta',
                        deger: 'deniz.yzlm@gmail.com',
                    },
                        {
                            id: 2,
                            tur: 'Telefon No',
                            deger: '05057816431',
                        },
                        {
                            id: 3,
                            tur: 'Web Sitesi',
                            deger: 'den-iz.com',
                        },
                    ],
                    hesaplar: [{
                        id: 1,
                        ad: 'deniz TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 2,
                            ad: 'deniz Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: [{
                        id: 1,
                        tur: 'Nüfus Cüzdanı',
                        seri: '123456',
                        serino: '1235467',
                        babaadi: 'Ahmet',
                        anneadi: 'Ayşe',
                        dogumyeri: 'Konak',
                        il: 35,
                        ilce: 1,
                        mahallekoy: 'İçmeler',
                        dogumtarihi: '2020-10-10',
                        ciltno: '21323',
                        ailesirano: '132546',
                        sirano: '111111',
                        aciklama: 'Bu bir açıklama metnidir',
                    },],
                    adresler: [{
                        id: 1,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Urla Gülbahçe 9111 sokak No 25',
                        il: 35,
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İzmir Urla İçmeler 1088 sokak No 5',
                            il: 34,
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                },
                {
                    personelBilgileri: {
                        id: 302,
                        personelTuru: 'Avukat',
                        adSoyad: 'Av. Gülşen Kaya',
                        tcNo: '33019850212',
                        cinsiyet: '1',
                        iseBaslamaTarihi: '2020-01-02',
                        istenAyrilmaTarihi: '',
                        maas: '8800',
                        sgkNo: '0235498761',
                        secilenAv: false,
                    },
                    iletisimler: [{
                        id: 513,
                        tur: 'E-posta',
                        deger: 'kaanylmz.yzlm@gmail.com',
                    },
                        {
                            id: 512,
                            tur: 'Telefon No',
                            deger: '05057816431',
                        },
                        {
                            id: 523,
                            tur: 'Web Sitesi',
                            deger: 'kaan-yilmaz.com',
                        },
                    ],
                    hesaplar: [{
                        id: 11,
                        ad: 'Kaan TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 12,
                            ad: 'Kaan Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: [{
                        id: 178,
                        tur: 'Nüfus Cüzdanı',
                        seri: '123456',
                        serino: '1235467',
                        babaadi: 'Ahmet',
                        anneadi: 'Ayşe',
                        dogumyeri: 'Konak',
                        il: 35,
                        ilce: 1,
                        mahallekoy: 'İçmeler',
                        dogumtarihi: '2020-10-10',
                        ciltno: '21323',
                        ailesirano: '132546',
                        sirano: '111111',
                        aciklama: 'Bu bir açıklama metnidir',
                    },
                        {
                            id: 256,
                            tur: 'Nüfus Cüzdanı',
                            seri: '123456',
                            serino: '1235467',
                            babaadi: 'Ahmet',
                            anneadi: 'Ayşe',
                            dogumyeri: 'Konak',
                            il: 35,
                            ilce: 'Merkez',
                            mahallekoy: 'İçmeler',
                            dogumtarihi: '2020-10-10',
                            ciltno: '21323',
                            ailesirano: '132546',
                            sirano: '111111',
                            aciklama: 'Bu bir açıklama metnidir',
                        },
                    ],
                    adresler: [{
                        id: 1112,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Urla Kuşçular 9111 sokak No 25',
                        il: 35,
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2112,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İzmir Urla İçmeler 1088 sokak No 5',
                            il: 34,
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                },
                {
                    personelBilgileri: {
                        id: 230,
                        personelTuru: 'Avukat',
                        adSoyad: 'Av. Ceylan Küçükyılmaz',
                        tcNo: '33019850213',
                        cinsiyet: '2',
                        iseBaslamaTarihi: '2020-01-02',
                        istenAyrilmaTarihi: '',
                        maas: '1800',
                        sgkNo: '0235498762',
                        secilenAv: false,
                    },
                    iletisimler: [{
                        id: 61,
                        tur: 'E-posta',
                        deger: 'cey.yzlm@gmail.com',
                    },
                        {
                            id: 62,
                            tur: 'Telefon No',
                            deger: '05057816431',
                        },
                        {
                            id: 63,
                            tur: 'Web Sitesi',
                            deger: 'cey-yilmaz.com',
                        },
                    ],
                    hesaplar: [{
                        id: 13,
                        avukatId: 1,
                        ad: 'cey TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 24,
                            avukatId: 1,
                            ad: 'cey Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: [{
                        id: 123,
                        tur: 'Nüfus Cüzdanı',
                        seri: '123456',
                        serino: '1235467',
                        babaadi: 'Ahmet',
                        anneadi: 'Ayşe',
                        dogumyeri: 'Konak',
                        il: 35,
                        ilce: 1,
                        mahallekoy: 'İçmeler',
                        dogumtarihi: '2020-10-10',
                        ciltno: '21323',
                        ailesirano: '132546',
                        sirano: '111111',
                        aciklama: 'Bu bir açıklama metnidir',
                    },
                        {
                            id: 232,
                            tur: 'Nüfus Cüzdanı',
                            seri: '123456',
                            serino: '1235467',
                            babaadi: 'Ahmet',
                            anneadi: 'Ayşe',
                            dogumyeri: 'Konak',
                            il: 35,
                            ilce: 'Merkez',
                            mahallekoy: 'İçmeler',
                            dogumtarihi: '2020-10-10',
                            ciltno: '21323',
                            ailesirano: '132546',
                            sirano: '111111',
                            aciklama: 'Bu bir açıklama metnidir',
                        },
                    ],
                    adresler: [{
                        id: 41,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Urla Kuşçular 9111 sokak No 25',
                        il: 35,
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 42,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İzmir Urla İçmeler 1088 sokak No 5',
                            il: 34,
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                },
                {
                    personelBilgileri: {
                        id: 1,
                        personelTuru: 'Avukat',
                        adSoyad: 'Av. Demir Süleyman',
                        tcNo: '123412341234123',
                        cinsiyet: '2',
                        iseBaslamaTarihi: '2020-01-02',
                        istenAyrilmaTarihi: '',
                        maas: '8810',
                        sgkNo: '2235498761',
                        secilenAv: true,
                    },
                    iletisimler: [{
                        id: 1,
                        tur: 'E-posta',
                        deger: 'deniz.yzlm@gmail.com',
                    },
                        {
                            id: 2,
                            tur: 'Telefon No',
                            deger: '05057816431',
                        },
                        {
                            id: 3,
                            tur: 'Web Sitesi',
                            deger: 'den-iz.com',
                        },
                    ],
                    hesaplar: [{
                        id: 1,
                        ad: 'deniz TL',
                        tur: 'Vadesiz TL Hesabı',
                        bankaMi: '2',
                        iban: 'TR-131353464567',
                        bankaAdi: '',
                        subeAdi: '',
                        hesapNo: '',
                    },
                        {
                            id: 2,
                            ad: 'deniz Dolar',
                            tur: 'Vadesiz Dolar Hesabı',
                            bankaMi: '2',
                            iban: 'USD-12325342131256',
                            bankaAdi: '',
                            subeAdi: '',
                            hesapNo: '',
                        },
                    ],
                    kimlikBilgileri: [{
                        id: 1,
                        tur: 'Nüfus Cüzdanı',
                        seri: '123456',
                        serino: '1235467',
                        babaadi: 'Ahmet',
                        anneadi: 'Ayşe',
                        dogumyeri: 'Konak',
                        il: 35,
                        ilce: 1,
                        mahallekoy: 'İçmeler',
                        dogumtarihi: '2020-10-10',
                        ciltno: '21323',
                        ailesirano: '132546',
                        sirano: '111111',
                        aciklama: 'Bu bir açıklama metnidir',
                    },],
                    adresler: [{
                        id: 1,
                        adresTuru: 'Yurt İçi İşyeri Adresi',
                        postaKodu: '35014',
                        adres: 'İzmir Urla Gülbahçe 9111 sokak No 25',
                        il: 35,
                        ilce: 'Merkez',
                        mersisAdresi: true,
                    },
                        {
                            id: 2,
                            adresTuru: 'Yurt Dışı İşyeri Adresi',
                            postaKodu: '35430',
                            adres: 'İzmir Urla İçmeler 1088 sokak No 5',
                            il: 34,
                            ilce: 'Merkez',
                            mersisAdresi: false,
                        },
                    ],
                },
            ],
            faizler: [{
                id: 1,
                aciklama: 'Tutar Gir',
                oran: 0.00,
            },
                {
                    id: 2,
                    aciklama: 'Yasal Faiz',
                    oran: 9.00,
                },
                {
                    id: 2,
                    aciklama: 'Özel Faiz',
                    oran: 8.88,
                },
            ],
            hesapSekli: [{
                id: 1,
                aciklama: '360 gün',
            },
                {
                    id: 2,
                    aciklama: '365 gün',
                },
                {
                    id: 3,
                    aciklama: '360 gün Yıl-Ay-Gün olarak',
                },
                {
                    id: 4,
                    aciklama: '360 gün UYAP Yıllık',
                },
                {
                    id: 5,
                    aciklama: 'Aylık Hesap',
                },
            ],
            iller: [{
                id: 1,
                ad: 'Adana'
            },
                {
                    id: 6,
                    ad: 'Ankara'
                },
                {
                    noid: 34,
                    ad: 'İstanbul'
                },
                {
                    id: 35,
                    ad: 'İzmir'
                },
            ],
            ilceler: [{
                id: 1,
                ad: 'Merkez',
                ilId: 1,
            },
                {
                    id: 2,
                    ad: 'Kızılay',
                    ilId: 6,
                },
                {
                    id: 3,
                    ad: 'Üsküdar',
                    ilId: 34,
                },
                {
                    id: 4,
                    ad: 'Konak',
                    ilId: 35,
                },
            ],
    
        };
    }
}