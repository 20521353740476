import {UserRole} from "@/store";

export const MasterMetaData = {
    roles: [UserRole.MASTER]
};
export const UserMetaData = {
    roles: [UserRole.MASTER, UserRole.USER]
};
export const EveryOneMetaData = {
    roles: [UserRole.MASTER, UserRole.USER, UserRole.GUEST]
}
export const GuestMetaData = {
    roles: [UserRole.GUEST]
}
export const UnknownMetaData = {
    roles: [UserRole.GUEST]
}

