



















import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class LoadingScreen extends Vue {
    userType = this.$store.getters.userType;
    mounted(){
        console.log();
    }

    @Watch('userType')
    onUserTypeChange(){
        this.$store.dispatch('goHome');
    }


}
