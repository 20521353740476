





























import {Vue, Component, Prop, Mixins} from "vue-property-decorator";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
//import ErrorMessage from "@/components/messages/ErrorMessage.vue";
import Loading from "@/components/Loading.vue";
@Component({
    components: {Loading},
})

export default class BasicForm<S> extends Vue{

    @Prop({default:false}) displayCancelButton!:boolean;
    @Prop({default:'POST'}) method!:string;
    @Prop({default:''}) url!:string;
    @Prop({}) action!:string;
    @Prop({default:"Submit"})
    submitButtonLabel!:string;
    cancelButtonLabel:string="Cancel";

    @Prop({})
    data!:S;

    error:AxiosError|null = null;

    config:AxiosRequestConfig = {
        headers:[]
    };

    onFly:boolean=false;

    mounted(){
        console.log("basic form mounted")
        if(this.action){
            this.config.url = this.action;
        }else {
            this.config.url = this.$route.path;
        }

        if(this.method){
            switch (this.method.toUpperCase()) {
                case "GET":
                    this.config.method = "GET";
                    break;
                case "POST":
                    this.config.method = "POST";
                    break;
                case "PUT":
                    this.config.method = "PUT";
                    break;
                case "DELETE":
                    this.config.method = "DELETE";
                    break;

                default:
                    break;
            }
        }else{
            this.config.method = "POST";
        }

        if(this.$attrs.submitButtonLabel){
            this.submitButtonLabel = this.$attrs.submitButtonLabel;
        }


    }

    reset(){
        this.error=null;
    }

    cancel(){
        this.$emit('cancel');
    }

    get buttonLabel(){
        return;
        //return this.$t(this.submitButtonLabel);
    }

    onSubmit(e:Event){
        e.preventDefault();
        this.config.data = this.data;
        this.config.url = this.url;

        if( !this.url ){
            this.$emit('submit');
        }else{
            Vue.prototype.$http.request(this.config).then( (response:AxiosResponse)=>{
                console.log(response);
                this.$emit('success', response.data );
            }).catch( (error:AxiosError)=>{
                this.error = error;
                console.log("err",error);
            }).finally( ()=>{
                this.onFly=false;
            });
        }
    }
}

