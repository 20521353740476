"use strict";

import Vue from 'vue';
import axios from "axios";
import "./api";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
 axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '/api/';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
_axios.defaults.headers.common['Content-Type']="application/x-www-form-urlencoded";
_axios.interceptors.request.use(
  function(config) {

      if(config.url && config.url.substr(0,1) == "/"){
        config.baseURL = "";
      }
      // Do something before request is sent
      if(window.localStorage.hasOwnProperty("accessToken")){
          let token = localStorage.getItem("accessToken");
          config.headers['Authorization'] = 'Bearer '+token;
      }else{
          delete config.headers['Authorization'];
      }
      return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);


// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export function AxiosPlugin<AxiosPlugOptions>(Vue: any, options?: AxiosPluginOptions): void {
  // do stuff with options
  Vue.prototype.$http = _axios;
}
export class AxiosPluginOptions {
  // add stuff
}
