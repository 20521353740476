import Vue from 'vue';
import Vuex from 'vuex';
import {RawLocation} from "vue-router";
import {VueRouter} from "vue-router/types/router";
import {AxiosResponse} from "axios";
import {User, Token} from "../models/service/Models";
import {simulator} from "./simulator";
import {LoginService, ValidateKeyResponse} from "@/services/LoginService/index";
import App from "@/App.vue";

Vue.use(Vuex);
interface EkAlan{
    label:string,
    active:boolean,
    key:string,
}
interface Preferences{
    ekAlanlar:Array<EkAlan>
}

export enum Portal{
    eicrapro,
    ofispro,
    icrapro
}

export enum UserRole {
    UNKNOWN = "unknown",
    GUEST = "guest",
    USER = "user",
    MASTER = "master",
}

export interface AppState {
    accessToken: string | null;
    key: string | null;
    tenantToken: string | null;
    user: User | null;
    role: UserRole;
    initialTarget: RawLocation | null;
    router: VueRouter | null,
    prefs: Preferences | null,
}

export const store = new Vuex.Store({
    state: {
        tenantToken: null,
        accessToken: null,
        key: null,
        user: null,
        initialTarget: null,
        router: null,
        role: UserRole.UNKNOWN,
        editingVekaletObject: Object,
        prefs:{
            ekAlanlar:[
                {label:'Föy Numarası', active:true,key:'ekAlan1'},
                {label:'Müşteri Numarası', active:false,key:'ekAlan2'},
                {label:'Sayaç Numarası', active:true,key:'ekAlan3'},
            ]
        },
        ...(new simulator).data
    },
    actions: {
        go(state, target) {
            state.getters.router.push(target);
        },
        goHome(state, target) {
            this.dispatch("go", state.getters.home);
        },

        login(state, user) {
            console.log("state", state);
            console.log(user);
        },
        logout(state) {
            localStorage.clear();
            state.commit("setUserAsGuest");
            state.dispatch("go", "/giris");
        },
        authInit(state) {
            if (localStorage.hasOwnProperty("accessToken")) {
                console.log("token is found");
                return this.dispatch("authValidateToken");
            } else {
                console.log("token is not found, set as guest");
                return new Promise((s, f) => {
                    state.commit("setUserAsGuest");
                    s();
                });
            }
        },
        authValidateToken(state) {
            console.log("state::authValidateToken started");
            let token = localStorage.getItem("accessToken");
            return new Promise((resolve, reject) => {
                if (token == null) {
                    window.localStorage.removeItem('accessToken');
                    this.commit('setUserAsGuest');
                    return resolve();
                } else {
                    try{
                        LoginService.validateKey({token: token}).then((responseData: ValidateKeyResponse) => {
                            if(responseData.user){
                                this.commit('setUser',responseData.user);
                            }else{
                                this.commit('setUserAsGuest');
                            }
                            return resolve()
                        }).catch(()=>{
                            this.commit('setUserAsGuest');
                            return resolve();
                        });
                    } catch (e) {
                        console.log("Validate failed, must set as guest");
                        this.commit('setUserAsGuest');
                        return resolve();
                    }
                }
            });
        }
    },
    mutations: {
        clearInitialTarget(state:AppState){
            state.initialTarget = null;
        },

        setUserAsGuest(state: AppState) {
            window.localStorage.clear();
            state.accessToken = null;
            state.key = null;
            state.tenantToken = null;
            state.user = null;
            state.role = UserRole.GUEST;
        },
        setInitialTarget(state: AppState, route: RawLocation) {
            console.log("setting initial target to vuex", route);
            state.initialTarget = route;
        },
        setRouter(state: AppState, router: VueRouter) {
            state.router = router;
        },
        setUser(state: AppState, user: User) {
            console.log("setUser", user);
            state.user = user;
            switch (state.user.role) {
                case UserRole.GUEST:
                    state.role = UserRole.GUEST;
                    break;
                case UserRole.MASTER:
                    state.role = UserRole.MASTER;
                    break;
                case UserRole.USER:
                    state.role = UserRole.USER;
                    break;
                default:
                    state.role = UserRole.UNKNOWN;
                    break;
            }
            console.log("current role is set to ", state.role);
        },
        setAccessToken(state: AppState, accessToken: string) {
            state.accessToken = accessToken;
            localStorage.setItem("accessToken", accessToken);
        },
        setKey(state: AppState, key: string) {
            state.key = key;
            localStorage.setItem("key", state.key);
        },
        setTenantToken(state: AppState, tenantToken: string) {
            state.tenantToken = tenantToken;
            localStorage.setItem("tenantToken", state.tenantToken);
        },

    },
    getters: {
        userType: (state: AppState) => {
            console.log("getter worked here", state.role);
            return state.role;
        },
        router: (state: AppState) => state.router,
        initialTarget: (state: AppState) => state.initialTarget,
        isUnknown: (state: AppState) => state.role == UserRole.UNKNOWN,
        isGuest: (state: AppState) => state.role == UserRole.GUEST,
        isUser: (state: AppState) => state.role == UserRole.USER,
        isMaster: (state: AppState) => state.role == UserRole.MASTER,
        isLoggedIn: (state: AppState) => state.role == UserRole.USER || state.role == UserRole.MASTER,
        takipEkAlanlar:( state:AppState) => state.prefs?.ekAlanlar.filter((i)=>i.active),
        isDev: (state: AppState) => !window.location.hostname.includes('.com') && true ,
        isEicraPro: (state:AppState) => window.location.host.startsWith("dev.eicrapro.lcl")
            || window.location.host.startsWith("eicrapro.view.icrapro.com"),
        isIcraPro: (state:AppState) => window.location.host.startsWith("dev.icrapro.lcl")
            || window.location.host.startsWith("web.view.icrapro.com"),
        isOfisPro: (state:AppState) => window.location.host.startsWith("dev.ofispro.lcl")
            || window.location.host.startsWith("ofispro.view.icrapro.com"),
        home: (state: AppState) => {
            switch (state.role) {
                case UserRole.UNKNOWN:
                    return "/";
                case UserRole.GUEST:
                    return "/giris";
                case UserRole.MASTER:
                    return "/Anasayfa";
                case UserRole.USER:
                    return "/Anasayfa";
            }
        },
    }
});
