



































import {Component, Vue, Watch, Prop} from "vue-property-decorator";
@Component
export default class MenuBar extends Vue {
    mounted(){
        console.log("router:",this.$store.state.router);
    }

    @Prop() menuid!:string;


    items:any=[];
    /*   get items() {
           if (this.$store.getters.isEicraPro) {
               return this.eicraProItems;
           } else if(this.$store.getters.isIcraPro){
               console.log("host:",window.location.pathname);
               if(window.location.pathname.includes("/takip/")){
                   return this.anakartItems;
               }
               return this.icraProItems;
           } else {
               return  this.ofisProItems;
           }
       }
       */
    isActive(route: string) {
        return window.location.pathname == route;
    }
    eicraProItems = [
        {
            baslikAdi: "Takipler",
            route: "/takipler",
            icon: "mdi-book-multiple-outline"
        },
        {
            baslikAdi: "Takip Aç",
            route: "/takip-ac",
            icon: "mdi-file-send-outline"
        },
        {
            baslikAdi: "MTS Takip Aç",
            route: "/mts-takip-ac",
            icon: "mdi-file-star-outline"
        },
        {
            baslikAdi: "MTS Tebligat Gönder",
            route: "/mts-tebligat",
            icon: "mdi-send"
        },
        {
            baslikAdi: "MTS Ödeme Yap",
            route: "/mts-odeme",
            icon: "mdi-contactless-payment"
        },
        {
            baslikAdi: "Harç / Masraf Ödeme",
            route: "/harc-masraf-odeme",
            icon: "mdi-contactless-payment"
        },
        {
            baslikAdi: "Evrak Gönder",
            route: "/evrak-gonder",
            icon: "mdi-file-send-outline"
        },
        {
            baslikAdi: "Hazır Evrak Gönder",
            route: "/hazir-evrak-gonder",
            icon: "mdi-file-send"
        },
        {
            baslikAdi: "Takip Sorgula",
            route: "/takip-sorgula",
            icon: "mdi-file-search-outline"
        },
        {
            baslikAdi: "Kişi Kurum Sorgula",
            route: "/kisi-kurum-sorgula",
            icon: "mdi-card-search-outline"
        },
        {
            baslikAdi: "Avukat / Vekil Sorgula",
            route: "/vekil-sorgula",
            icon: "mdi-badge-account-horizontal-outline"
        }, {
            baslikAdi: "Deneme Tahtası",
            route: "/deneme-tahtasi",
            icon: "mdi-test-tube"
        },
    ];
    anakartItems = [
        {
            baslikAdi: "Cari Kart",
            route: "/cari-kart",
            icon: "mdi-safe-square-outline"
        },
        {
            baslikAdi: "Dilekçeler",
            route: "/dilekceler",
            icon: "mdi-file-document-outline"
        },
        {
            baslikAdi: "Makbuz",
            route: "/makbuz",
            icon: "mdi-file-document"
        },
        {
            baslikAdi: "İşler",
            route: "/isler",
            icon: "mdi-file-document"
        },

    ];
    icraProItems = [
        {
            id: 1,
            baslikAdi: "Takipler",
            route: null,
            icon: "mdi-account-voice",
            altbaslik: true,
            altbasliklar: [
                {
                    id: 1,
                    baslikAdi: "Takip Aç",
                    route: "/yeniTakip",
                    icon:"mdi-plus",
                },
                {
                    id: 2,
                    baslikAdi: "Takip Listesi",
                    route: "/takipler",
                    icon:"mdi-format-list-numbered",
                },
            ],
        },
        {
            id: 2,
            baslikAdi: "Tercihler",
            route: "/tercihler",
            icon: "mdi-atom",
            altbaslik: false,
            altbasliklar: null,
        },
        {
            id: 3,
            baslikAdi: "Oyun Alanı",
            route: "/playground",
            icon: "mdi-basketball-hoop-outline",
            altbaslik: false,
            altbasliklar: null,
        },
        {
            id: 4,
            baslikAdi: "Oyun Alanı 2",
            route: "/Anasayfa",
            icon: "mdi-basketball-hoop-outline",
            altbaslik: false,
            altbasliklar: null,
        },
    ];
    ofisProItems = [
        {
            id: 1,
            baslikAdi: "Bürolar",
            route: "/burolar",
            icon: "mdi-bank",
            altbaslik: false,
            altbasliklar: null,
        },
        {
            id: 2,
            baslikAdi: "Vekalet",
            route: null,
            icon: "mdi-account-voice",
            altbaslik: true,
            altbasliklar: [
                {
                    id: 1,
                    baslikAdi: "Vekaletnameler",
                    route: "/vekaletler",
                    icon:"mdi-file",
                },
                {
                    id: 2,
                    baslikAdi: "Müvekkiller",
                    route: "/muvekkiller",
                    icon:"mdi-format-list-numbered",
                },
            ],
        },
        {
            id: 3,
            baslikAdi: "Raporlar",
            route: "/raporlar",
            icon: "mdi-file-chart",
            altbaslik: false,
            altbasliklar: null,
        },
        {
            id: 4,
            baslikAdi: "Dilekçeler",
            route: "/dilekceler",
            icon: "mdi-file-document-edit",
            altbaslik: false,
            altbasliklar: null,
        },
        {
            id: 5,
            baslikAdi: "Kasa",
            route: "/kasa",
            icon: "mdi-safe-square",
            altbaslik: false,
            altbasliklar: null,
        },
        {
            id: 6,
            baslikAdi: "Klasörler",
            route: "/klasorler",
            icon: "mdi-folder",
            altbaslik: false,
            altbasliklar: null,
        },
        {
            id: 7,
            baslikAdi: "Ajanda",
            route: "/ajanda",
            icon: "mdi-calendar",
            altbaslik: false,
            altbasliklar: null,
        },
    ]
    @Watch('$route', { immediate: true, deep: true })
    onUrlChange(newVal: any) {
        if (this.$store.getters.isEicraPro) {
            this.items= this.eicraProItems;
        } else if(this.$store.getters.isIcraPro){
            if (this.menuid=="anakart"){
                this.items=this.anakartItems;
            }else
            this.items=  this.icraProItems;
        } else {
            this.items=   this.ofisProItems;
        }
    }
}
