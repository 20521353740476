







import {Component, Vue} from "vue-property-decorator";
import UserLayout from "./layout/page/UserLayout.vue";
import GuestLayout from "./layout/page/GuestLayout.vue";
@Component({
    components: {UserLayout, GuestLayout}
})

export default class App extends Vue {

};
             // ana renk ve detay ikonu       light-blue darken-4
                                            // mdi-arrow-right-bold-box
