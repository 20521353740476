

























import {Component, Mixins, Vue} from "vue-property-decorator";
import GuestLayout from "@/layout/page/GuestLayout.vue";
import LoginForm from "@/components/account/LoginForm.vue";
import Branding from "@/components/Branding.vue";
import {store} from "@/store";
import router from "@/router";

@Component({
    components:{
        Branding,
        LoginForm,GuestLayout
    }
})
export default class Login extends Vue{
    onLogin(){
        const target = this.$store.getters.initialTarget;
        if( target == null ){
            this.$store.dispatch('goHome');
        }else{
            this.$router.push( target ).catch(()=>{
                this.$store.dispatch('goHome');
            })
        }
    }

    log(){

    }
};
