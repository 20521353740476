<template>
    <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="E-Posta"
            @keypress="validate()"
    ></v-text-field>
</template>
<script>
    export default {
        props: ['value'],
        data () {
            return {
                email: this.value,
                rules: {
                    required: value => !!value || 'Zorunlu',
                    counter: value => value.length <= 20 || 'Maksimum 20 karakter olmalıdır.',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-Posta yanlış.'
                    },
                },
            }
        },
        validate() {
            this.$emit("value", this.email);
        }

    }
</script>