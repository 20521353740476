











import {Component, Prop, Vue} from "vue-property-decorator";
@Component({

})
export default class Password extends  Vue{
    @Prop({

    })
    value!:String;

    @Prop({})
    label!:string|null;

    val:String=this.value ? this.value : '';

    get labelKey():String{
        console.log(this.label);
        return this.label ? this.label : 'Şifre';
    }

}
