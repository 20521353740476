
















import {Component, Prop, Vue} from "vue-property-decorator";
import Branding from "@/components/Branding.vue";

@Component({
    components: {Branding}
})
export default class Loading extends Vue{
    @Prop({})
    size!:number;

    @Prop({default:false})
    displayBrand!:boolean;

    csize = this.size?this.size:36;
    width = Math.ceil(this.csize/10);
}
