




















import {Component, Vue} from "vue-property-decorator";
@Component({
    components: {}
})
export default class AppFooter extends Vue {
    links : any = [
            'Ana Sayfa',
            'Hakkımzıda',
            'Destek',
            'İletişim',
        ];
}
