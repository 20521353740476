





























































import {Component, Vue, Watch} from "vue-property-decorator";
import AppFooter from "@/layout/AppFooter.vue";
import NavigationBar from "@/components/page-components/NavigationBar.vue";
import MenuBar from "@/components/page-components/MenuBar.vue";
import UserInfoCard from "@/components/account/UserInfoCard.vue";

@Component({
    components: {
        UserInfoCard,
        MenuBar,
        NavigationBar,
        AppFooter
    }
})
export default class UserLayout extends Vue {
    color: string = 'blue';
    drawer: boolean = true;
    mode: string = '';
    snackbar: boolean = false;
    text: string = 'Ana Sayfaya gitmek istiyor musunuz?';
    timeout: number = 3000;
    x: string | null = null;
    y: string | null = 'top';
    Urunler = [
        {
            id: 1,
            isim: "OfisPro",
            logo: require('@/content/DavaProweb-logo.png'),
            color: "",
            color2: "black",
            btnColor: "white",
            route: "http://dev.ofispro.lcl:8080/",
            route2: "http://ofispro.view.icrapro.com/",
            secili: false,
        },
        {
            id: 2,
            isim: "icraPro",
            logo: require('@/content/icraprologo.jpg'),
            color: "blue--text",
            color2: "blue",
            btnColor: "white",
            route: "http://dev.icrapro.lcl:8080/",
            route2: "http://web.view.icrapro.com/",
            secili: false,
        },
        {
            id: 3,
            isim: "e-icraPro",
            logo: require('@/content/eicraprologo.png'),
            color: "amber--text text--darken-4",
            color2: "amber darken-4",
            btnColor: "white",
            route: "http://dev.eicrapro.lcl:8080/",
            route2: "http://eicrapro.view.icrapro.com/",
            secili: false,
        },
        {
            id: 4,
            isim: "DavaPro",
            logo: require('@/content/DavaProweb-logo.png'),
            color: "deep-orange--text text--darken-4",
            color2: "deep-orange darken-4",
            btnColor: "white",
            route: "",
            route2: "",
            secili: false,
        },

        {
            id: 5,
            isim: "ArabulucuPro",
            logo: require('@/content/DavaProweb-logo.png'),
            color: "light-blue--text text--darken-4",
            color2: "light-blue darken-4",
            btnColor: "white",
            route: "",
            route2: "",
            secili: false,
        },
    ]
    secimiDegis(urun:any){
        this.Urunler.forEach((x:any)=>{
            x.secili = false;
        })
        urun.secili = true;
    }
    getRoute(id:number){
        if (this.$store.getters.isDev)
        return this.Urunler[id-1].route;
        else
            return this.Urunler[id-1].route2;
    }
    Anasayfa() {
        this.$router.push("/Anasayfa");
        this.snackbar = false;
    }
    pathKontrol(){
       if(window.location.pathname.includes("/takip/"))
       {
           return true;
       }else
           return false;
    }
    mounted(){
        if (this.$store.getters.isEicraPro)
            this.Urunler[2].secili=true;
        if (this.$store.getters.isIcraPro)
            this.Urunler[1].secili=true;
        if (this.$store.getters.isOfisPro)
            this.Urunler[0].secili=true;
    }
}


/*
    <v-btn v-if="$store.getters.isIcraPro" class="ma-2" color="blue" outlined tile>
    İcraPro Web
    <v-icon right>mdi-android-auto</v-icon>
    </v-btn>
    <v-btn v-if="$store.getters.isEicraPro" class="ma-2" color="blue" outlined tile>
    E-İcraPro+
    <v-icon right>mdi-android-auto</v-icon>
    </v-btn>
 */
