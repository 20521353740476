
































































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class UserInfoCard extends Vue {
    menu:Boolean=false;

}
