import _Vue from "vue";
import {KisiKurumEntity, KisiKurum} from "@/entity/KisiKurumEntity";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {HesapNumarasiEntity} from "@/entity/HesapNumarasiEntity";

export class Helper {

    tarihFarkiGunSayisi(begin: Date, end: Date) {
        var date1 = new Date(begin.toISOString().substr(0, 10));
        var date2 = new Date(end.toISOString().substr(0, 10));
        let num = (date2.getTime() - date1.getTime()) / 86400000;
        return num > 0 ? num : num * -1;
    }

    tarihSaatStr(val: Date | null): string {
        if (val === null) {
            return "";
        }
        try {
            return this.tarihStr(val) + " " + this.saatStr(val);
        } catch (e) {
            return "";
        }
    }

    kisiKurumAd(val: any): string {
        if (val.kisiKurumTuru == KisiKurumTuru.sahis) {
            return val.ad + ' ' + val.soyad;
        } else if (val.kisiKurumTuru == KisiKurumTuru.sirket) {
            return val.ad;
        } else {
            return ""
        }

    }

    hesapNoOrIbanStr(row: HesapNumarasiEntity): string {
        if (row.ibanMi) {
            return row.iban === null ? "" : row.iban;
        } else {
            return row.subeKodu + " - " + row.hesapNo;
        }
    }

    tcknOrVergiNo(val: any): string {
        if (val.kisiKurumTuru == KisiKurumTuru.sahis) {
            return val.tc
        }
        if (val.kisiKurumTuru == KisiKurumTuru.sirket) {
            return val.vergiNo
        }
        return ""

    }

    tutarStr(val: number): string {
       // let temp = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'}).format(val);
        let temp = new Intl.NumberFormat('tr-TR').format(val);
        if(temp.search(',')==-1){
            return  temp+",00 ₺"
        }
        return temp+" ₺";
    }

    tarihStr(val: Date | null): string {
        if (val === null) {
            return "";
        }
        try {
            return val.toISOString().substr(0, 10).split('-').reverse().join('/')
        } catch (e) {
            return "";
        }
    }

    saatStr(val: Date | null): string {
        if (val === null) {
            return "";
        }
        try {
            return val.toTimeString().substr(0, 5);
        } catch (e) {
            return "";
        }
    }

    /**
     * @TODO Girilen tarihe göre,
     * @param val
     */
    tarihGecenSure(val: Date | null): string {
        var thisTime = new Date();              // now
        if (val === null)
            return "bos deger";
        var diff = thisTime.getTime() - val.getTime();
        var retvaldk = diff / (1000 * 60); // dk bazında fark
        var retvalsa = diff / (1000 * 60 * 60); // sa bazında fark
        var retvalgun = diff / (1000 * 60 * 60 * 24); // gün bazında fark
        var retvalhafta = diff / (1000 * 60 * 60 * 24 * 7); // hafta bazında fark
        var retvalay = diff / (1000 * 60 * 60 * 24 * 30); // ay bazında fark
        var retvalyil = diff / (1000 * 60 * 60 * 24 * 365); // ay bazında fark
        if (retvaldk < 60)
            return Math.round(retvaldk).toString() + " dk önce";
        else if (retvalsa < 24)
            return Math.round(retvalsa).toString() + " saat önce";
        else if (retvalgun < 7)
            return Math.round(retvalgun).toString() + " gün önce";
        else if (retvalhafta < 5)
            return Math.round(retvalhafta).toString() + " hafta önce";
        else if (retvalay < 12)
            return Math.round(retvalay).toString() + " ay önce";
        else
            return Math.round(retvalyil).toString() + "yıl önce";
    }

    tcKimlikNoValidate(tcKimlikNo: string): boolean {
        let toplam = Number(tcKimlikNo.substring(0, 1)) + Number(tcKimlikNo.substring(1, 2)) +
            Number(tcKimlikNo.substring(2, 3)) + Number(tcKimlikNo.substring(3, 4)) +
            Number(tcKimlikNo.substring(4, 5)) + Number(tcKimlikNo.substring(5, 6)) +
            Number(tcKimlikNo.substring(6, 7)) + Number(tcKimlikNo.substring(7, 8)) +
            Number(tcKimlikNo.substring(8, 9)) + Number(tcKimlikNo.substring(9, 10));
        let strtoplam = String(toplam);
        let onunbirlerbas = strtoplam.substring(strtoplam.length, strtoplam.length - 1);
        if (onunbirlerbas == tcKimlikNo.substring(10, 11)) {
            return true;
            //alert("doğru");
        } else {
            return false;
            //alert("yanlış");
        }
    }

    arrRemoveItem(arr: Array<any>, item: any) {
        var index = arr.indexOf(item);
        if (index != -1) {
            arr.splice(index, 1);
        }
    }
}

export default function HelperPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$helper = new Helper();
}


