





























































































import Component from "vue-class-component";
import BasicForm from "@/layout/form/BasicForm.vue";
import Email from "@/components/inputs/EPosta.vue";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import Password from "@/components/inputs/Password.vue";
import {Vue} from "vue-property-decorator";
import {store} from "@/store";
import router from "@/router";

class LoginRequestPayload {
    email: String = "emrecaliskann@outlook.com";
    password: String = "12345";
}

class lostPasswordRequestPayload {
    email: String = "";
}

class changePasswordRequestPayload {
    email: String = "";
    password: String = "";
    repassword: String = "";
    hash: String = "";
}

interface LoginResponse {
    key: string;
    url: string;
}

interface ClassicResponse {
    message: string;
}

interface NLoginResponse{
    accessToken:string;
    account:{
        key:string,
        token:string,
        url:string,
        user:{
            id:number,
            lisans_no:number,
            role:string,
            context_data:any,
            email:string,
        }
    },

}

@Component({
    components: {Password, Email, BasicForm}
})
export default class LoginForm extends Vue {
    loginRequest: LoginRequestPayload = new LoginRequestPayload();
    lostPasswordRequest: lostPasswordRequestPayload = new lostPasswordRequestPayload();
    changePasswordRequest: changePasswordRequestPayload = new changePasswordRequestPayload();
    step: string = "login";
    onFly: boolean = false;

    dialog:boolean=false;
    errorMessage: string = 'Kullanıcı bilgileri geçerli değil';
    errorTitle: string = '';

    login(e: Event) {
        e.preventDefault();
        this.onFly = true;
        Vue.prototype.$http.post("http://service.icrapro.com/icrapro/login", this.loginRequest).then((response: AxiosResponse<LoginResponse>) => {
            console.log("response", response);
            //this.$store.commit('setUser',response.data.user);
            //this.$store.commit('setToken',response.data.token);
            //this.$emit("login");

            this.$http.post('/api/auth/login', response.data).then((resp2: AxiosResponse<NLoginResponse>) => {

                console.log("RESPONSE FROM NEST", resp2.data);
                console.log(resp2.data.account.user);

                this.$store.commit('setAccessToken',resp2.data.accessToken);
                this.$store.commit('setUser',resp2.data.account.user);
                this.$store.commit('setKey',resp2.data.account.key);
                this.$store.commit('setTenantToken',resp2.data.account.token);
                this.$emit("login");
                // this.$store.commit('setToken',response.data.token);
                //
            }).catch((err: AxiosError) => {
                this.errorMessage = err.response?.data.message;
                this.dialog=true;
            }).finally(() => {
                this.onFly = false;
            });


            /*
            TODO Guards eklencek burada kontrol edilcek.
            */
        }).catch((err: AxiosError) => {
            this.errorMessage = err.response?.data.message;
            this.dialog=true;
            this.onFly=false;
            //alert(err.response.data.message);
        })
    }

    lostPassword(e: Event) {
        e.preventDefault();
        Vue.prototype.$http.post("http://service.icrapro.com/icrapro/lostPassword", this.lostPasswordRequest).then((response: AxiosResponse<ClassicResponse>) => {
            console.log("response", response);
            alert(response.data.message);
            this.step = "changePassword";
            this.changePasswordRequest.email = this.lostPasswordRequest.email;
        }).catch((err: any) => {
            alert(err.message);
        })
    }

    changePassword(e: Event) {
        e.preventDefault();
        Vue.prototype.$http.post("http://service.icrapro.com/icrapro/changePassword", this.changePasswordRequest).then((response: AxiosResponse<ClassicResponse>) => {
            console.log("response", response);
            alert(response.data.message);
            this.step = "changePassword";
        }).catch((err: any) => {
            console.log(err.response);
            alert(err.message);
        })
    }

}
