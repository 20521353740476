import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import {store, UserRole} from "@/store";
import LoadingScreen from "@/views/common/LoadingScreen.vue";
import Login from "@/views/common/Login.vue";
import {GuestMetaData, UnknownMetaData, UserMetaData} from "@/router/meta";

Vue.use(VueRouter)


const defaultRoutes: RouteConfig[] = [
    {path: '/', component: LoadingScreen, meta: UnknownMetaData},
    {path: '/giris', component: Login, meta: GuestMetaData}
];


const icraProRoutes : RouteConfig[] = [
    {path: '/takipler/', component: () => import('@/views/icrapro/takipler/TakipListView.vue'), meta: UserMetaData},
    {path: '/takip/:id/detay', component: () => import('@/views/icrapro/takipler/takip/TakipDetay.vue'), meta: UserMetaData},
    {path: '/yeniTakip/', component: () => import('@/views/icrapro/takipler/YeniTakip.vue'), meta: UserMetaData},
    {path: '/Anasayfa', component: () => import('@/views/icrapro/Anasayfa.vue'), meta: UserMetaData},
    {path: '/tercihler', component: () => import('@/views/icrapro/tercihler/Tercihler.vue'), meta: UserMetaData},
    {path: '/takipAltTip/:id', component: () => import('@/views/icrapro/tercihler/TakipAltTip.vue'), meta: UserMetaData},
    {path: '/playground', component: () => import('@/views/Playground.vue'), meta: UserMetaData},
    {path: '/cari-kart/', component: () => import('@/views/icrapro/cari-kart/CariKart.vue'), meta: UserMetaData},
    {path: '/borclu/:id/detay', component: () => import('@/views/icrapro/borclu/BorcluDetay.vue'), meta: UserMetaData},

];
const eicraProRoutes : RouteConfig[] = [
    {path: '/takipler', component: () => import('@/views/eicrapro/Takipler.vue'), meta: UserMetaData},
    {path: '/takip-ac', component: () => import('@/views/eicrapro/TakipAc.vue'), meta: UserMetaData},
    {path: '/mts-takip-ac', component: () => import('@/views/eicrapro/MtsTakipAc.vue'), meta: UserMetaData},
    {path: '/mts-tebligat', component: () => import('@/views/eicrapro/MtsTebligat.vue'), meta: UserMetaData},
    {path: '/mts-odeme', component: () => import('@/views/eicrapro/MtsOdeme.vue'), meta: UserMetaData},
    {path: '/harc-masraf-odeme', component: () => import('@/views/eicrapro/HarcMasrafOde.vue'), meta: UserMetaData},
    {path: '/evrak-gonder', component: () => import('@/views/eicrapro/EvrakGonder.vue'), meta: UserMetaData},
    {path: '/takip-sorgula', component: () => import('@/views/eicrapro/TakipSorgula.vue'), meta: UserMetaData},
    {path: '/kisi-kurum-sorgula', component: () => import('@/views/eicrapro/KisiKurumSorgula.vue'), meta: UserMetaData},
    {path: '/vekil-sorgula', component: () => import('@/views/eicrapro/VekilSorgula.vue'), meta: UserMetaData},
    {path: '/islemler', component: () => import('@/views/eicrapro/Islemler.vue'), meta: UserMetaData},
    {path: '/deneme-tahtasi', component: () => import('@/views/eicrapro/DenemeTahtasi.vue'), meta: UserMetaData},

];
const ofisProRoutes : RouteConfig[] = [
    {path: '/burolar', component: () => import('@/views/icrapro/buro/BuroListe.vue'), meta: UserMetaData},
    {path: '/buro/:id/detay', component: () => import('@/views/icrapro/buro/BuroDetay.vue'), meta: UserMetaData},
    {path: '/personel/:id/detay', component: () => import('@/views/icrapro/buro/personel/PersonelDetay.vue'), meta: UserMetaData},
    {path: '/vekaletler/', component: () => import('@/views/icrapro/vekalet/VekaletListe.vue'), meta: UserMetaData},
    {path: '/vekalet/:id/detay', component: () => import('@/views/icrapro/vekalet/VekaletDetay.vue'), meta: UserMetaData},
    {path: '/muvekkil/:id/detay', component: () => import('@/views/icrapro/vekalet/muvekkil/MuvekkilDetay.vue'), meta: UserMetaData},
    {path: '/vekalet/ekle', component: () => import('@/views/icrapro/vekalet/VekaletEkle.vue'), meta: UserMetaData},
    {path: '/Anasayfa', component: () => import('@/views/icrapro/Anasayfa.vue'), meta: UserMetaData},
    {path: '/dilekceler', component: () => import('@/views/icrapro/dilekce/index.vue'), meta: UserMetaData},
    {path: '/klasorler', component: () => import('@/views/icrapro/tercihler/Klasorler.vue'), meta: UserMetaData},
    {path: '/ajanda', component: () => import('@/views/ofispro/Ajanda.vue'), meta: UserMetaData},
    {path: '/kasa', component: () => import('@/views/ofispro/Kasa.vue'), meta: UserMetaData},
    {path: '/muvekkiller', component: () => import('@/views/icrapro/vekalet/muvekkil/MuvekkilListe.vue'), meta: UserMetaData},
];

let routes: RouteConfig[];

let webAddress:any = {
    eicrapro:'eicrapro.view.icrapro.com',
    icrapro:'web.view.icrapro.com',
    ofispro:'ofispro.view.icrapro.com'
}

switch (true) {
    case window.location.host.startsWith(webAddress.eicrapro):
        console.log("using eicrapro routes");
        routes = defaultRoutes.concat(eicraProRoutes)
        break;
    case window.location.host.startsWith(webAddress.icrapro):
        console.log("using icrapro routes");
        routes = defaultRoutes.concat(icraProRoutes);
        break;
    case window.location.host.startsWith(webAddress.ofispro):
        console.log("using ofispro routes");
        routes = defaultRoutes.concat(ofisProRoutes)
        break;
    default:
        console.log("using default routes");
        routes = defaultRoutes.concat(icraProRoutes);
        break;
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    next();
    return;

    if (store.getters.userType == UserRole.UNKNOWN) {
        store.dispatch('authInit').then((result) => {
            const target = store.getters.initialTarget;
            if (target == null) {
                store.dispatch('goHome');
            } else {
                router.push(target)
            }
            console.log("router::authInit.then");
        }).catch(() => {
            const target = store.getters.initialTarget;
            if (target == null) {
                store.dispatch('goHome');
            } else {
                router.push(target)
            }
        });
        if (to.path != '/') {
            store.commit('setInitialTarget', to.path);
            next('/');
        }
    } else {
        console.log("router:to", to);
        console.log("router:to.meta", to.meta);

        if (to.meta.roles.includes(store.getters.userType)) {
            next();
        } else {
            if (to.path != '/') {
                store.commit('setInitialTarget', to.path);
            }
            next(store.getters.home);
        }
    }

});


export default router
