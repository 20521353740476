import Vue from 'vue';
import {AxiosResponse} from "axios";

import {LoginServicePayload} from "@/services/LoginService/LoginServicePayload";
import {LoginServiceResponse} from "@/services/LoginService/LoginServiceResponse";
import {ValidateKeyPayload} from "@/services/LoginService/ValidateKeyPayload";
import {ValidateKeyResponse} from "@/services/LoginService/ValidateKeyResponse";

export {LoginServicePayload} from "@/services/LoginService/LoginServicePayload";
export {LoginServiceResponse} from "@/services/LoginService/LoginServiceResponse";
export {ValidateKeyPayload} from "@/services/LoginService/ValidateKeyPayload";
export {ValidateKeyResponse} from "@/services/LoginService/ValidateKeyResponse";

export class LoginService {

    public static login(payload: LoginServicePayload): Promise<AxiosResponse<LoginServiceResponse>> {
        return Vue.prototype.$http.post('/api/auth/login', payload);
    }

    public static validateKey(payload: ValidateKeyPayload): Promise<ValidateKeyResponse> {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.get('/api/auth/validate').then((response:AxiosResponse<ValidateKeyResponse>)=>{
                return resolve(response.data)
            }).catch(()=>{
                return reject();
            })

        })
    }

}
