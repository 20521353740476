export enum KisiKurumTuru{
    bilinmiyor="bilinmiyor",
    sahis="sahis",
    sirket="sirket"
}
export enum KisiKurumTuruLabel{
    bilinmiyor="Bilinmiyor",
    sahis="Şahıs",
    sirket="Şirket"
}
